import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../layouts/index'

const WorkItem = ({ title, description, color, image, align }) => {
  if (align === 'left') {
    return (
      <div className={'callout work ' + color}>
        <div className="grid-x">
          <div className="cell small-10 small-offset-1 medium-5">
            <img className="screenshot" src={image} alt={title} />
          </div>
          <div className="cell small-10 small-offset-1 medium-3 medium-offset-2">
            <h2>{title}</h2>
            {description}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={'callout work ' + color}>
        <div className="grid-x">
          <div className="cell small-10 small-offset-1 medium-3 medium-offset-1">
            <h2>{title}</h2>
            {description}
          </div>
          <div className="cell small-10 small-offset-1 medium-5 medium-offset-2">
            <img className="screenshot" src={image} alt={title} />
          </div>
        </div>
      </div>
    )
  }
}

class Works extends React.Component {
  render() {
    return (
      <div>
        {this.props.data.map((item, idx) => (
          <WorkItem
            key={idx}
            {...item}
            align={idx % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      </div>
    )
  }
}

class Work extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const works = [
      {
        title: '911 International Law Enforcement Gear',
        description: (
          <p>
            911Gear is a Canadian law enforcement and security supply store
            located near Toronto Ontario. It was a pleasure to work with 911Gear
            team and the whole effort resulted in a flawless migration from CRE
            Loaded to Magento 2.
          </p>
        ),
        image: 'works/911gear.jpg',
        color: 'grey',
      },
      {
        title: 'ColemanBook',
        description: (
          <p>
            We helped Coleman Book Company to migrate from CRE Loaded to{' '}
            <b>Magento 2</b> platform.
          </p>
        ),
        image: 'works/colemanbook.jpg',
        color: 'gray',
      },
      {
        title: 'Luxsol',
        description: (
          <p>
            Luxsol decided to expand their production of security and other
            types of nets to Slovak Republic and we were happy to help them
            build their online business on top of <b>Magento platform</b>.
          </p>
        ),
        image: 'works/luxsol.jpg',
        color: 'blue',
      },
      {
        title: 'A.p. Menzies: Writer / Musician',
        description: (
          <p>
            <b>Apmenzies.com</b> - is a personal website for an amazing writer
            and musician A.p. Menzies. Wordpress-based web-site with an
            interesting concept of in-page navigation.
          </p>
        ),
        image: 'works/apm.jpg',
        color: 'grey',
      },
      {
        title: 'Zlavan',
        description: (
          <div>
            <p>Zlavan is a Slovakian offers marketplace.</p>
            <p>
              <b>Built with:</b> Ruby On Rails, React.js
            </p>
          </div>
        ),
        image: 'works/zlavan.jpg',
        color: 'gray',
      },
    ]

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />

          <div className="pagehead">
            <div className="pagehead__wrap black small" />
          </div>

          <Works data={works} />
        </div>
      </Layout>
    )
  }
}

export default Work

export const pageQuery = graphql`
  query WorkQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
